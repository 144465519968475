<template>
  <div class="active-promotion">
    <portal to="breadcrumb">
      <div
        class="users-filter d-flex align-items-center justify-content-sm-between"
      >
        <div class="user-list-breadcrumb">
          <bread-crumb
            :activeModule="$route.meta.moduleLabel"
            :activePage="$route.meta.label"
            :activePageUrl="$route.path"
          />
        </div>
        <div class="filter position-relative" v-clickoutside="closeFilter">
          <b-button variant="brand-secondary" @click="showFilter = !showFilter"
            ><i class="icon-filter"></i> Filter</b-button
          >
          <div
            class="filter-menu p-3 bg-white shadow-sm radius-25 position-absolute"
            v-if="showFilter"
            :class="filterStyle"
          >
            <b-form-group
              label-for="sites"
              label-class="form-label"
              :label="$t(`active-promotion.table_head_siteName`)"
              v-slot="{ ariaDescribedby }"
            >
              <b-dropdown
                id="site-selection-form"
                :ariaDescribedby="ariaDescribedby"
                :text="$t(`active-promotion.table_head_siteName`)"
                ref="siteSelection"
                block
                no-caret
                lazy
                variant="brand-select"
                toggle-class="searchable-column position-relative"
                @show="toggleSiteDropdownFilter('open')"
                @hide="toggleSiteDropdownFilter('close')"
              >
                <b-dropdown-form @submit.stop.prevent>
                  <div class="form-group">
                    <b-form-input
                      type="search"
                      class="nosubmit w-100"
                      :placeholder="
                        $t(
                          `active-promotion.table_head_siteName-search-placeholder`
                        )
                      "
                      v-model="searchSiteText"
                    />
                  </div>
                  <b-form-checkbox-group
                    class="scrollable"
                    id="sites"
                    v-model="selected"
                    :options="sitesOptions"
                    name="sites"
                  ></b-form-checkbox-group>
                </b-dropdown-form>
              </b-dropdown>
            </b-form-group>
          </div>
        </div>
      </div>
    </portal>
    <div class="row mb-3 text-fileds">
      <div class="col-xl-3 col-md-4 col-12 sites-promotion-date">
        <div role="group">
          <label for="name" class="form-label">{{
            $t(`active-promotion.promotion-date`)
          }}</label>
          <div class="flex w-full">
            <v-date-picker
              :locale="selected_language"
              ref="selectDate"
              class="flex-grow"
              :masks="masks"
              v-model="selectDate"
              is-required
              :popover="{ visibility: 'click' }"
              :model-config="modelConfig"
              :available-dates="{
                start: new Date(),
                end: new Date(),
              }"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <div class="d-flex custom-datepicker">
                  <input
                    class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                    :value="inputValue"
                    @click="togglePopover"
                    :placeholder="dateFormat"
                    readonly
                  />
                  <div
                    class="picker-icon"
                    slot="afterDateInput"
                    role="button"
                    @click="togglePopover"
                  >
                    <i class="icon-date"></i>
                  </div>
                </div>
              </template>
            </v-date-picker>
          </div>
        </div>
      </div>
    </div>
    <b-card>
      <b-overlay :show="isLoading" rounded="sm">
        <product-preview-failed v-if="productPreviewFailed" />
        <div class="product-preview" v-else>
          <!-- <table-pagination
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :showExportButton="displayedRows.length > 0"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @paginate="paginate"
            :countInfo="'active-promotion.table_count-info'"
            @downloadData="download"
            class="mb-3"
          /> -->
          <div
            class="row d-md-flex justify-content-between align-items-center mb-0 mb-md-3"
          >
            <div
              class="col-xl-4 col-md-6 col-sm-12 pl-xxl-0 pr-md-0 mb-2 mb-md-2 mb-xl-0"
            >
              <input
                type="search"
                v-model="searchValue"
                :placeholder="$t(`active-promotion.table_search_placeholder`)"
                class="nosubmit w-100"
              />
            </div>
            <div class="mt-2 mt-md-0">
              <b-link
                class="export-csv my-md-0 my-2 col-sm-12"
                :disabled="totalRecords == '00'"
                @click="download"
                >{{ $t(`general.export-csv`) }}
                <i class="icon-download ml-1"></i>
              </b-link>
            </div>
          </div>
          <b-table
            hover
            responsive
            :items="items"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            tbody-tr-class="text-nowrap"
            show-empty
            :empty-text="$t('general.no-data-msg')"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
            :filter-debounce="300"
            :filter-function="filterTable"
            v-model="displayedRows"
          >
            <!--- Custom configuration for table headers -->

            <template #head(products)>
              {{ $t(`active-promotion.table_head_products`) }}
            </template>

            <template #head(pricingManagerId)>
              {{ $t(`active-promotion.table_head_pricingManagerId`) }}
            </template>

            <template #head(siteName)>
              {{ $t(`active-promotion.table_head_siteName`) }}
            </template>

            <template #head(sku)>
              {{ $t(`active-promotion.table_head_sku`) }}
            </template>
            <template #head(vmn)>
              {{ $t(`active-promotion.table_head_vmn`) }}
            </template>
            <template #head(regularPrice)>
              {{ $t(`active-promotion.table_head_regularPrice`) }}
            </template>
            <template #head(promoPrice)>
              {{ $t(`active-promotion.table_head_promoPrice`) }}
            </template>

            <template #cell(pricingManagerId)="data">
              {{ data.item.pricingManagerId }}
            </template>

            <template #cell(siteName)="data">
              {{ data.item.siteName }}
            </template>

            <template #cell(products)="data">
              <div class="d-flex align-items-center">
                <b-img
                  src="/media/icons/rectangle.svg"
                  alt="Product Rectangle Icon"
                />
                <span class="text-wrap">{{ data.item.productName }}</span>
              </div>
            </template>

            <template #cell(sku)="data">
              {{ data.item.productId }}
            </template>

            <template #cell(vmn)="data">
              {{ data.item.vendorModelNumber }}
            </template>

            <template #cell(regularPrice)="data">
              $&nbsp;{{ data.item.regularPrice }}
            </template>

            <template #cell(promoPrice)="data">
              $&nbsp;{{ data.item.promoPrice }}
            </template>
          </b-table>
          <table-pagination
            class="mt-3"
            :fromLimit="fromLimit | addLeadingZero"
            :toLimit="toLimit | addLeadingZero"
            :totalRecords="totalRecords | addLeadingZero"
            :currentPage="currentPage"
            :totalPages="totalPages"
            @paginate="paginate"
            :countInfo="'active-promotion.table_count-info'"
          />
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, ceil } from "lodash";
import config from "../../../../config";
import moment from "moment";
import ProductPreviewFailed from "@/components/ProductPreviewFailed.vue";
import BreadCrumb from "../../../components/BreadCrumb.vue";
import TablePagination from "@/components/TablePagination.vue";

const { pricingManagerConfig, tableHeaders, exportCSVHeaders } = config;

export default {
  name: "ActivePromotion",
  metaInfo() {
    return {
      title: `${this.$t("general.active-promotions")} | ${this.$t(
        "general.pricing-manager"
      )}`,
    };
  },
  components: {
    ProductPreviewFailed,
    TablePagination,
    BreadCrumb,
  },
  data() {
    return {
      sitesOptions: [],
      searchSiteText: "",
      currentPage: 1,
      totalRecords: 0,
      totalPages: 0,
      selected: [],
      selectDate: new Date(),
      dateFormat: config.dateFormat,
      isLoading: false,
      showProductIcon: false,
      showFilter: false,
      showDate: false,
      masks: {
        input: config.dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
      formName: "active-promotion",
      perPage: pricingManagerConfig.activePomotionPerPage,
      filter: {
        searchValue: "",
        productName: "",
        pricingManagerId: "",
        siteName: [],
        productId: "",
        vendorModelNumber: "",
      },
      productName: "",
      pricingManagerId: "",
      sku: "",
      vmn: "",
      searchValue: "",
      fileTitle: "Active Promotion",
      displayedRows: [],
      filterOn: [],
      type: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", ["activePromotions"]),
    fields() {
      return tableHeaders[this.formName];
    },
    // getHeight() {
    //   if (this.type == "show") return "filter-height";
    //   else return "";
    // },
    csvFields() {
      return exportCSVHeaders[this.formName];
    },
    productPreviewFailed() {
      return this.activePromotions.length == 0 ? true : false;
    },
    productdata() {
      let data = this.activePromotions.map((item) => {
        let siteNames = item.siteName.split(",");
        return { ...item, siteNames: siteNames };
      });
      return data;
    },
    items() {
      return this.productdata;
    },
    fromLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.fromlimit;
    },
    toLimit() {
      let response = this.getPaginationResultLimit(
        this.currentPage,
        this.perPage,
        this.totalRecords
      );
      return response.tolimit;
    },
    csvHeader() {
      let header = {};
      forEach(this.csvFields, (field) => {
        header[field.key] = field.label;
      });
      return header;
    },
  },
  watch: {
    searchSiteText(newValue) {
      if (this.siteAccess.length > 0) {
        let newOptions = [];
        forEach(this.siteAccess, (site) => {
          if (
            site.Name.toLowerCase().includes(newValue.toLowerCase()) ||
            newValue == ""
          ) {
            let obj = {
              text: site.Name,
              value: { siteId: site.SiteId, siteName: site.Name },
            };
            newOptions.push(obj);
          }
        });
        this.sitesOptions = newOptions;
      }
    },
    searchValue(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("searchValue");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("searchValue");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["searchValue"] = newValue;
    },
    selected(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("siteName");

      if (newValue.length) {
        if (index == -1) {
          _filterOn.push("siteName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["siteName"] = newValue.length
        ? newValue.map((a) => a.siteName)
        : [];
    },
    productdata(newValue) {
      this.currentPage = 1;
      if (newValue.length) {
        this.totalRecords = newValue.length;
        this.totalPages = ceil(newValue.length / this.perPage);
      }
    },
    productName(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("productName");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("productName");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["productName"] = newValue;
    },
    pricingManagerId(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("pricingManagerId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("pricingManagerId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["pricingManagerId"] = newValue;
    },
    sku(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("productId");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("productId");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["productId"] = newValue;
    },
    vmn(newValue) {
      let _filterOn = this.filterOn;
      let index = _filterOn.indexOf("vendorModelNumber");

      if (newValue != "") {
        if (index == -1) {
          _filterOn.push("vendorModelNumber");
        }
      } else {
        if (index > -1) {
          _filterOn.splice(index, 1);
        }
      }
      this.filterOn = _filterOn;
      this.filter["vendorModelNumber"] = newValue;
    },
  },
  methods: {
    ...mapActions("pricingManager", ["getActivePromotions"]),
    ...mapMutations("pricingManager", ["setItem"]),
    ...mapMutations({
      generalSetItem: "setItem",
    }),
    async getPromotions() {
      let _siteIds = this.selected.map((_selected) => _selected.siteId);
      this.setItem({ resource: "activePromotions", payload: [] });
      this.generalSetItem({ resource: "isLoading", payload: true });
      this.getActivePromotions({
        siteIds: _siteIds.join(),
        selectDate: new Date(this.selectDate).toISOString(),
        userId: this.loggedUserId,
      })
        .then(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        })
        .catch(() => {
          this.generalSetItem({ resource: "isLoading", payload: false });
        });
    },
    toggleHeight(type) {
      this.type = type;
    },
    paginate(eventType) {
      if (eventType == "next") {
        this.currentPage = this.currentPage + 1;
      } else {
        this.currentPage = this.currentPage - 1;
      }
    },
    closeFilter() {
      this.type = "hide";
      this.showFilter = false;
      this.toggleSiteDropdownFilter("close");
    },
    download() {
      if (this.displayedRows.length == 0) return;
      try {
        this.generalSetItem({ resource: "isLoading", payload: true });
        let headers = this.csvHeader;
        let productdata = [];
        let filter = this.filter;

        if (filter.siteName.length > 0) {
          this.productdata.forEach((row) => {
            let isConditionVerified = this.isConditionVerified(row, filter);
            if (isConditionVerified) {
              productdata.push(row);
            }
          });
        }

        let csvData = this.prepareCSVdata(headers, productdata);

        let fileTitle =
          this.fileTitle + "_" + moment(new Date()).format("YYYY/MM/DD");

        this.generalSetItem({ resource: "isLoading", payload: false });

        this.exportCSVFile(headers, csvData, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
      } catch {
        this.generalSetItem({ resource: "isLoading", payload: false });
      }
    },
    prepareCSVdata(headers, productdata) {
      let csvData = [];
      forEach(productdata, (data) => {
        let _csvData = {};
        forEach(headers, (val, key) => {
          _csvData[key] = "";

          if (key == "productId") {
            if ("productId" in data) {
              _csvData[key] = String(data.productId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "pricingManagerId") {
            if ("pricingManagerId" in data) {
              _csvData[key] = String(data.pricingManagerId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "products") {
            _csvData[key] = String(data.productName).replace(/,/g, ""); // remove commas to avoid errors,
          }

          if (key == "vmn") {
            if ("vendorModelNumber" in data) {
              _csvData[key] = String(data.vendorModelNumber).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "sku") {
            if ("productSKU" in data) {
              _csvData[key] = String(data.productSKU).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }

          if (key == "regularPrice") {
            if ("regularPrice" in data) {
              _csvData[key] = String(data.regularPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "promoPrice") {
            if ("promoPrice" in data) {
              _csvData[key] = String(data.promoPrice).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "siteId") {
            if ("siteId" in data) {
              _csvData[key] = String(data.siteId).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
          if (key == "siteName") {
            if ("siteName" in data) {
              _csvData[key] = String(data.siteName).replace(/,/g, ""); // remove commas to avoid errors,
            }
          }
        });
        csvData.push(_csvData);
      });
      return csvData;
    },
    isConditionVerified(row, filter) {
      let filterCondition = {};

      if (this.filterOn.length) {
        this.filterOn.forEach((item) => {
          if (filter[item].length) {
            filterCondition[item] = true;
            if (item == "siteName") {
              let existingSites = {};
              row.siteNames.forEach((site) => {
                existingSites[site] = false;
                if (filter[item].includes(site)) existingSites[site] = true;
              });
              const areTrue = Object.values(existingSites).some(
                (value) => value === true
              );
              filterCondition[item] = areTrue;
            } else if (item == "searchValue") {
              let productNameMatched = row["productName"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let pmIdMatched = row["pricingManagerId"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let productIdMatched = row["productId"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());
              let vmnMatched = row["vendorModelNumber"]
                .toLowerCase()
                .includes(filter[item].toLowerCase());

              filterCondition[item] =
                productNameMatched ||
                pmIdMatched ||
                productIdMatched ||
                vmnMatched;
            }
          }
        });
      }

      const areTrue = Object.values(filterCondition).every(
        (value) => value === true
      );
      return areTrue;
    },
    filterTable(row, filter) {
      if (filter.siteName.length == 0) return false;

      let isConditionVerified = this.isConditionVerified(row, filter);
      return isConditionVerified;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
      this.totalRecords = filteredItems.length;
      this.totalPages = ceil(filteredItems.length / this.perPage);
    },
  },
  beforeMount() {
    let options = [];
    forEach(this.siteAccess, (site) => {
      let obj = {
        text: site.Name,
        value: { siteId: site.SiteId, siteName: site.Name },
      };
      options.push(obj);
    });
    this.sitesOptions = options;
  },
  mounted() {
    this.selected = [];
    this.filterOn = [];
    this.searchSiteText = "";
    this.productName = "";
    this.searchValue = "";
    this.pricingManagerId = "";
    this.sku = "";
    this.vmn = "";
    this.filter = {
      searchValue: "",
      productName: "",
      pricingManagerId: "",
      siteName: [],
      productId: "",
      vendorModelNumber: "",
    };
    forEach(this.sitesOptions, (option) => {
      this.selected.push(option.value);
    });
    this.getPromotions();
  },
  beforeDestroy() {
    this.setItem({ resource: "activePromotions", payload: [] });
  },
};
</script>
